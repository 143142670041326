
import { RevButtonTiny } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'

import BlockTitle from '../private/BlockTitle'

export default {
  components: {
    RevButtonTiny,
    BlockTitle,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasLinks() {
      return !isEmpty(this.links)
    },
  },
  methods: {
    getTarget({ link }) {
      return link?.targetBlank ? '_blank' : false
    },
  },
}
